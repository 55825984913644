import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '157, 2, 68',
		'primary-dark': '125, 1, 53',
		'accent': '186, 188, 190',
		'accent-plus': '30, 30, 31',
	},
});
